import axios from 'axios';
const router = useRouter();

const apiClient = axios.create({
    baseURL: 'https://sgestion-api-staging.vml.pe/v1.0/',
    headers: {
        'Content-Type': 'application/json',
    },
});

apiClient.interceptors.request.use((config) => {
    const token = localStorage.getItem('authToken');
    console.log("token interceptors",token)
    if (token) {
        config.headers.Authorization = `Token ${token}`;
    }
    return config;
    }, (error) => {
    return Promise.reject(error);
});

apiClient.interceptors.response.use(
    (response) => {
      // Si la respuesta es exitosa, simplemente devuelve la respuesta
      return response;
    },
    (error) => {
      // Si hay un error y el código de estado es 401, redirige al login
      console.log("error",error)
      if (error.response && error.response.status === 401) {
        router.push('/login');
      }
  
      // Rechaza la promesa con el error original
      return Promise.reject(error);
    }
  );

export default apiClient
